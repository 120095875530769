import React from 'react';
import Header from '../components/Header';

const Dining = () => {
  return (
    <div>
      <Header />
      Dining
    </div>
  );
};

export default Dining;
